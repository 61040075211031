<template>
 <div>
    <md-field>
    <label v-text="input.title"></label>
    <md-input v-model="input.value" />
    </md-field>
    </div>
</template>
<script>


export default {
    props: ["input"],
  data(){
      return {
      }
  },
  computed:{
  },
  methods:{
  }
}
</script>
