<template>
  <div class="home centered">
        <div  v-show="!hide_events" >
          <h3>פגישות</h3>
          <Tickets type="event" handler="me" :upcoming="true" @no_results="hide_events=true" />
        </div>

        <div  v-show="!hide_missions">
          <h3>משימות</h3>
          <Tickets type="mission" filter_by="pending" handler="me" :past_missions="true" @no_results="hide_missions=true" />
        </div>
      
  </div>
</template>

<script>
import Tickets from '@/components/tickets/index.vue';

export default {
  components: { Tickets },
  methods: {},
      
  data(){
    return {
      hide_events: false,
      hide_missions: false,
    }
    
  },
  mounted(){
  }

}
</script>