import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

import {store} from './store/store'

import VueResource from 'vue-resource'
Vue.use(VueResource);
/*
import { BootstrapVue } from 'bootstrap-vue'
Vue.use(BootstrapVue)
*/
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
//import 'vue-material/dist/theme/default-dark.css'
Vue.use(VueMaterial)


import VueSimpleAlert from "vue-simple-alert"; 
Vue.use(VueSimpleAlert);


import Toasted from 'vue-toasted'; 
Vue.use(Toasted, { position: 'top-left'})


import './app.scss' 

if (window.location.href.indexOf('http://localhost') === -1)  {
  document.domain = "crm.nadlancenter.co.il";
}


Vue.mixin({
 
  data(){
    return {
    }
  },
  computed: {
    user(){
      if(!this.isNull(localStorage.getItem('user_token'))) 
      {
        return true;
      }

      return false;
    },
  },
  methods: {
    get_me(){
      return this.$store.user;
    },
    get_user_name(id){
      return (!this.isNull(this.$store.users[id]) ? this.$store.users[id].display_name : '');
    },
    get_priority_name(id){
      return (!this.isNull(this.$store.prioritiesList[id]) ? this.$store.prioritiesList[id] : '');
    },
    format_number: function(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    isNull: function (str)
    {
      return (str == '' || str == null || str == undefined);
    },

    parse_date(str){
      var d=new Date(str); return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();
    },
   
/*
    get_list_arr(list)
    {
      var ret = [];

    },
*/
  net_api: function(settings, func, failFunc){
    
    // var is_test = false;


     var headers = {
       'Content-Type': 'application/json; charset=utf-8',
     };
    
     if(!this.isNull(localStorage.getItem('user_token'))) 
     { 
       headers.Authorization = localStorage.getItem('user_token');
     }
   

    // if (!is_test)
    // {
       if (settings.method == 'get')
       {
         this.$http.get(window.api_url + settings.action, /* settings.data,*/ {/*emulateJSON:true,*/ headers: headers})
         .then(response => {
            if (response.body == 'error')
            {
              localStorage.setItem('user_token', '');
              document.location.reload();
            }
             func(response.body);
         }, response => { 
           if (!this.isNull(failFunc)) failFunc(response);
           else func(false);
         });

       }
       else
       {
         this.$http.post(window.api_url + settings.action, settings.data, {/*emulateJSON:true,*/ headers: headers})
         .then(response => {
          if (response.body == 'error')
          {
            localStorage.setItem('user_token', '');
            document.location.reload();
          }
             func(response.body);
             //console.log(response.body);
         }, response => { 
           if (!this.isNull(failFunc)) failFunc(response);
           else func(false);
             //console.error(response.body);
         });
     }
     

   },

    is_mobile: function(){ 

          return ( navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      // || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      );
    },


   }
  })


new Vue({
  router,
  store: store,
  render: h => h(App)
}).$mount('#app')
