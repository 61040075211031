

<template>
  <div>
      <VoerroTagsInput :validate="check_mail" @tags-updated="onChange" placeholder="הוספת נמענים" v-model="valueComp" :typeahead="true"></VoerroTagsInput>

  </div>
</template>

<script>
import VoerroTagsInput from '@voerro/vue-tagsinput';
import '@voerro/vue-tagsinput/dist/style.css';

export default {
  props: ["input", "value"],
  components: {
    VoerroTagsInput,
  },
  data() {
    return {
      tags: [],
      valueComp: []
    };
  },
  mounted() {
    
    var ret = []; 

    var val;
    if (!this.isNull(this.input)) val = this.input.value;
    else val = this.value;

    for (let i in val)
    {
        ret.push({ key: val[i], value: val[i]});
    }

    this.valueComp = ret;

  },
  methods: {
      check_mail(email){

        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());

      },
      onChange()
      { 
        if (!this.isNull(this.input)) this.input.value = this.valueComp.map(x => x.value);
        else this.value = this.valueComp.map(x => x.value);

            this.$emit('tags-changed', this.value);
          
      }
  }
};
</script>