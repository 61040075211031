<template>
  <div class="home centered">
    
      <div class="box" id="filter_search">
          <h3>חיפוש חברות</h3>

          <ul>
              <li>
                 <md-field>
                    <label>שם החברה</label>
                    <md-input v-model="filter.name"></md-input>
                 </md-field>
              </li>
          </ul>
          <div class="clr"></div>
      </div>

      <div class="box">
        <TableObject :cols="cols" :rows="rows" :options="tblActions" />
      </div>
  </div>
</template>

<script>
import TableObject from '@/components/table.vue';

export default {
  components: { TableObject },
  methods: {
      
      init_list(){
          var self = this;

        self.rows = [];

        this.net_api({ 'action': 'companies/results', data: {search: this.filter } }, function(data){
            
            self.$emit('loaded');
            //console.log(data);
            self.rows = data.data.items;
            self.lastupdated = Date.now()

        });
    },

    tblActions(/*rows*/){
        var self = this;

        return [
            {
                title: "כרטיס חברה",
                onClick: function(rows){
                    self.$router.push('company/' + rows.id);
                }
            },
            {
                title: "מחיקה",
                onClick: function(){
                }
            }
        ];
    
    },
  
  },
  data(){
    return {
        filter: {
            name: '',
        },
      rows: [],
      cols: [
        {title: 'שם', field: 'name'},
      ]
    }
    
  },

  mounted(){
      this.init_list()
  },
  watch: {
      
    filter: {
        handler(){
            this.init_list()
        // do stuff
        },
        deep: true
    }
  }

}
</script>

<style lang="scss" scoped>
#filter_search { 
    li { width:33%; float: right; padding: 0 15px; }
}
</style>