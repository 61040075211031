<template>
 <div>
     <md-field>
      <label>{{input.title}}</label>
    <span class="md-prefix">₪</span>
      <md-input  v-model="input.value" type="number"></md-input>
    </md-field>
    </div>
</template>
<script>


export default {
    props: ["input"],
  data(){
      return {
      }
  },
  computed:{
  },
  methods:{
  }
}
</script>

