<template>
 <div>
        <md-checkbox v-model="input.value" value="1">{{input.title}}</md-checkbox>
    </div>
</template>
<script>


export default {
    props: ["input"],
  data(){
      return {
      }
  },
  computed:{
  },
  methods:{
  }
}
</script>
