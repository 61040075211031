<template>
 <div>
        <form class="form_container" @submit.prevent="onSubmit" v-if="loaded">
            <ul>
                <li v-for="input in inputs" :class="input.type=='group' ? 'group ' + input.class : ''" :key="input.name">
                    <div v-if="input.type == 'group'">
                        <h3 v-text="input.title"></h3>
                        <ul>
                            <li v-for="input2 in input.inputs" :key="input2.name"><GetInput  :input="input2"  /></li>
                        </ul>
                    </div>
                    <GetInput v-else :input="input" />
                </li>
            </ul>
            <div class="clr"></div>
            <div class="submit_area"><input type="submit" value="שמירה" class="btn"></div>
        </form>
    </div>
</template>
<script>


import GetInput from './getInput.vue';
export default {
    components: { GetInput },
    props: ["form_function","id"],
  data(){
      return {
          loaded: false,
          inputs: [],
      }
  },
  computed:{
      is_update(){
          return (this.update_id > 0);
      },
      
  },
  methods:{
      get_saved_data()
      {
          var ret = this.inputs.map(function(row) { if (row.type != 'group') { return { name: row.name, value: row.value }; } })

            var groups = [];
            for (let i in this.inputs)
            {
                if (this.inputs[i].type == 'group')
                {
                    groups.push(...this.inputs[i].inputs);
                }
            }
            console.log(groups)
            ret.push(...groups.map(function(row) { return { name: row.name, value: row.value }; }));

          return ret;
      },
      onSubmit(){

        var self = this;

        var form_data = this.get_saved_data();
        

        this.net_api({ action: 'forms/' + this.form_function, data: { save: true, id: this.id, postdata: form_data }}, function(data){ 

            if (data.data.status === false)
            {
                self.$toasted.show("המידע לא נשמר, אנא תקן ונסה שנית", { 
                theme: "toasted-primary", 
                position: "top-left", 
                duration : 5000,
                type: 'error'
                });
                
            }
            else
            {
                self.$toasted.show("המידע נשמר", { 
                theme: "toasted-primary", 
                position: "top-left", 
                duration : 5000,
                type: 'success'
                });
            }
            self.id = data.data.new_id;
            self.$emit('saved', data.data);

        });
      }
  },
  

  mounted(){
    var self = this;
    this.net_api({ action: 'forms/' + this.form_function, data: { id: this.id} }, function(data){ 

      self.inputs = data.data;

      self.loaded = true;
    });
  },
  watch:{
      inputs:
      {
        deep: true,
        handler() {
            this.$emit('changed', this.inputs)
        }
            
    }
    
  }
}
</script>
<style lang="scss" scoped>

</style>