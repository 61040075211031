<template>
 <div>
        <TextInput v-if="input.type=='text'" :input="input" :key="input.name" />
        <SelectInput v-else-if="input.type=='select'" :input="input" :key="input.name" />
        <CheckboxInput v-else-if="input.type=='checkbox'" :input="input" :key="input.name" />
        <AutoCompleteInput v-else-if="input.type=='autocomplete'" :input="input" :key="input.name" />
        <Date v-else-if="input.type=='date'" :input="input" :key="input.name" />
        <Number v-else-if="input.type=='number'" :input="input" :key="input.name" />
    </div>
</template>
<script>


import TextInput from './inputs/text.vue';
import SelectInput from './inputs/select.vue';
import CheckboxInput from './inputs/checkbox.vue';
import AutoCompleteInput from './inputs/autocomplete.vue';
import Date from './inputs/date.vue';
import Number from './inputs/number.vue';
export default {
    components: { TextInput, SelectInput, CheckboxInput, AutoCompleteInput, Date ,Number},
    props: ["input"],
}
</script>
<style lang="scss" scoped>
</style>

