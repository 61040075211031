<template>
  <div class="home centered">
    
      <div class="box" id="filter_search">
          <h3>חיפוש לקוח</h3>

          <ul>
              <li>
                 <md-field>
                    <label>שם הלקוח</label>
                    <md-input v-model="filter.name"></md-input>
                 </md-field>
              </li>
              <li>
                 <md-field>
                    <label>טלפון</label>
                    <md-input v-model="filter.phone"></md-input>
                 </md-field>
              </li>

              <li>
                <md-autocomplete v-model="filter_product_comp" :md-options="products_ac">
                    <label>מוצר</label>
                </md-autocomplete>

              </li>
              <li v-if="$store.user.has_manager_perms">
                 <md-field>
                    <label>נציג</label>
                    <md-select v-model="filter.proccess_handler">
                        <md-option value="">כולם</md-option>
                        <md-option v-for="handler in $store.users" :key="handler.id" :value="handler.id">{{handler.display_name}}</md-option>
                    </md-select>
                 </md-field>
              </li>
              <li>
                 <md-field>
                    <label>מחלקה</label>
                    <md-select v-model="filter.proccess_department">
                        <md-option v-for="dep in $store.departments" :value="dep.id" :key="dep.id">{{dep.name}}</md-option>
                    </md-select>
                 </md-field>
              </li>
              <li>
                  <VueCtkDateTimePicker v-model="filter.created_date" range="true" format="YYYY-MM-DD" label="תאריך כניסה:" />
              </li>
              <li>
                  <VueCtkDateTimePicker v-model="filter.proposal_sign_date" range="true" format="YYYY-MM-DD" label="תאריך הצעה:" />
              </li>
        
          </ul>
          <div class="clr"></div>
          <div>ייצא תוצאות ל: <a class="btn" @click="export_excel()">אקסל</a></div>
      </div>

      <div class="box">
        <TableObject :cols="cols" :rows="rows" />
      </div>
  </div>
</template>

<script>
import TableObject from '@/components/table.vue';


import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
 

export default {
  components: { TableObject, VueCtkDateTimePicker },
  methods: {
      
      init_list(){
          var self = this;

        self.rows = [];

        this.net_api({ 'action': 'reports/results', data: {search: this.filter } }, function(data){
            
            self.$emit('loaded');
            //console.log(data);
            self.rows = data.data.items;
            self.lastupdated = Date.now()

        });
    },


    export_excel(){
        
        this.net_api({ 'action': 'reports/export_excel', data: {search: this.filter } }, function(data){
       
            alert('https://admin.crm.nadlancenter.co.il/' + data.data)

        });
    }
  
  },
  data(){
      var self = this;
    return {
        search_timer: null,
        filter: {
            name: '',
            phone: '',
            email: '',
            company: '',
            company_type: '',
            proccess_product: '',
            proccess_department: '',
            proccess_date: '',
            proccess_status: '',
            created_date: {
                start: new Date().toISOString().slice(0, 10),
                end: new Date().toISOString().slice(0, 10),
            },
            proposal_sign_date: '',
            proccess_meeting_date: '',
            proccess_handler: '',
        },
      rows: [],
      
      cols: [
          
        {title: 'שם לקוח ',  func(rows){ return rows.client_data.name }},
        {title: 'טלפון', field: 'phone', func(rows){ return rows.client_data.phone }},
        {title: 'תאריך כניסת ליד', field: 'created_date', func(rows){ return self.parse_date(rows.created_date) }},
        {title: 'שעת כניסת ליד', field: 'created_date'},
        {title: ' מתעניין במוצר', field: 'product_id',func(rows){ return rows.product.name }},
        {title: ' מחלקה(פעילות)', field: 'department_name'},
        {title: 'מקור הגעה', field: 'lead_time'},
        {title: 'נציג מטפל ' ,func(rows){ return rows.handler.display_name }},
        {title: 'סטטוס  ', field: 'status_name'},
        {title: 'חוייב כן/לא  ', field: 'is_closed'},
        {title: ' מחיר סגירה  ', field: 'proposal_amount'},
        {title: ' תאריך סגירה  ', field: 'proposal_sign_date'},
        {title: ' ימים לסגירה אוטומטית  ', field: 'lead_time'},

      ]
    }
    
  },
  computed:{
      products_ac(){
          var newList = [];
          for (let i in this.$store.products) newList.push(this.$store.products[i].name);
          return newList;
      },
      filter_product_comp:
      {
          get()
          {
              let item = this.$store.products[this.filter.proccess_product];
              return (!this.isNull(item) ? item.name : '');
          },
          set(newVal)
          {
              var ret = '';
              for (let i in this.$store.products)
              {
                  if (this.$store.products[i].name == newVal)
                  {
                      ret = this.$store.products[i].id;
                      break;
                  }
              }

            this.filter.proccess_product = ret;
              return ret;
          }
      }
  },

  mounted(){
      this.init_list()
  },
  watch: {
      
    filter: {
        handler(){
            clearTimeout(this.search_timer);
            this.search_timer = setTimeout(() => { this.init_list() }, 400 );
            
        // do stuff
        },
        deep: true
    }
  }

}
</script>

<style lang="scss" scoped>

</style>