<template>
  <div id="bar">
      <ul>
          <li v-for="link in menu" :key="link.name" :class="($route.path == link.link ? 'active': '')"><router-link :to="link.link"><i class="fas fa-home"></i> <span v-text="link.name"></span></router-link></li>

      </ul>
  </div>
</template>

<script>
export default {
  methods: {
  },
    data() {
        return {
            menu: [
                {name: 'ראשי', link: '/', icon: ''},
                {name: 'לקוחות', link: '/clients'},
                {name: 'לוג שיחות', link: '/calls'},
                {name: 'דוחות', link: '/reports/marketing_efficiency'},
            ]
        };
    }
}
</script>
<style lang="scss">
#bar { 
    & { position: fixed; right:0px; top:0px; bottom: 0px; width:200px; background: #1D1B22; color:#fff }
    ul {
        li { display: block;
            a { display: block; color: #fff; padding: 15px 15px;}
            span { margin-right: 10px;}
        }
        li:hover { background: #2B282E; }
        li.active { background: #2B282E; border-left: 9px solid #FF4855;}
        
    }
}
</style>