<template>
  <div class="home centered">
    
    <div class="box">
      <FormObject form_function="proccess_details" :id="id" />
    </div>

      <h3>משימות / פגישות</h3>
        <Tickets :proccess_id="id" />
  </div>
</template>

<script>
import FormObject from '@/components/forms/form.vue';
import Tickets from '@/components/tickets/index.vue';

export default {
  components: { FormObject, Tickets },
  methods: {
  
  },
  data(){
    return {
      id: this.$route.params.id,
      rows: [ ],
      cols: [
        {title: 'מחלקה', field: 'department_name'},
        {title: 'סטאטוס', field: 'status_name'},
        {title: 'תיאור', field: 'description'},
      ]
    }
    
  },

  mounted() {

    var self = this;
    
      this.net_api({ action: 'proccess/results', data: {client_id: this.id} }, function(data){ 
        
        self.rows = data.data.items;
      } );
 
  }

}
</script>