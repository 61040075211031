import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import ClientPage from '../views/client_profile.vue'
import Clients from '../views/clients.vue'
import Proccess from '../views/proccess.vue'
import Companies from '../views/companies.vue'
//import new_client from '../views/new_client.vue'
import new_proccess from '../views/proccess.vue'
import reports from '../views/reports.vue'
import marketing_efficiency from '../views/reports/marketing_efficiency.vue'

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', component: Home },
  { path: '/client/:id', component: ClientPage},
  { path: '/proccess/:id', component: Proccess},
  { path: '/clients', component: Clients},
  { path: '/companies', component: Companies},
 // { path: '/new_client', component: new_client},
  { path: '/new_client', component: ClientPage},
  { path: '/new_proccess', component: new_proccess},
  { path: '/reports', component: reports},
  { path: '/reports/marketing_efficiency', component: marketing_efficiency},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
