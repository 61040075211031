<template>
 <div>
<md-datepicker v-model="input.value">
      <label>{{input.title}}</label>
    </md-datepicker>
    </div>
</template>
<script>


export default {
    props: ["input"],
  data(){
      return {
      }
  },
  computed:{
  },
  methods:{
  }
}
</script>
