<template>
  <div class="client_page centered">
    
    <h1 class="title" v-text="'כרטיס לקוח'"></h1>

    <div class="box">
      <FormObject form_function="client_details" :id="id" ref="clientFrm" @saved="onInsert" @changed="frmOnChange" />
    

      <div class="" v-if="show_company_details" :key="frm_data_company">
        <h3>פרטי חברה</h3>
        <FormObject v-if="frm_data_company_id > 0" form_function="company" :key="frm_data_company_id" :id="frm_data_company_id" ref="companyFrm" />
        <div v-else style="text-align:center;">
          <div style="margin-bottom:15px;">לא נמצאה חברה בשם <strong v-text="frm_data_company"></strong>, האם ברצונך ליצור חברה חדשה?</div>
          <div>
            <a class="btn" @click="createCompany">יצירת חברה</a>
          </div>
        </div>      
      </div>

      <div id="footer_save"><a class="btn" @click="save_all()">שמירה</a></div>
    </div>

    <div :key="id" v-if="id > 0">
      <h2 class="title">תהליכים</h2>      
      <div class="box">
        <router-link to="/new_proccess/" class="btn">יצירת תהליך חדש</router-link>
        <TableObject :cols="cols" :rows="rows" :options="tblActions" />
      </div>

      <div v-show="!hide_events">
        <h3>פגישות</h3>
        <Tickets type="event" :client_id="id"  @no_results="hide_events=true" />
      </div>
      <div v-show="!hide_missions">
        <h3>משימות</h3>
        <Tickets type="mission" :client_id="id" @no_results="hide_missions=true" />
      </div>
    </div>
  </div>
</template>

<script>
import TableObject from '@/components/table.vue';
import FormObject from '@/components/forms/form.vue';
import Tickets from '@/components/tickets/index.vue';

export default {
  components: { TableObject, FormObject, Tickets },
  methods: {
    save_all(){
      this.$refs.clientFrm.onSubmit();
      this.$refs.companyFrm.onSubmit();
    },
    onInsert(res){
      if (!(this.id > 0) && res.new_id > 0)
      {
        this.id = res.new_id;
        this.load_proccesses();
      }
    },
    frmOnChange(inputs)
    {
      var self = this;

      for (let i in inputs)
      {
        let input = inputs[i];
        switch (input.name)
        {
          case 'company_id':
          {
            this.frm_data_company = input.value;
            this.show_company_details = (input.value != '');
            break;
          }
          case 'is_business_manager':
          {
            //this.show_company_details = true; // (inputs[i].value == 1);
            break;
          }
          case 'phone':
          {
            if (input.value != '' && !this.isNull(this.frm_old_data.phone) && this.frm_old_data.phone != input.value)
            {
              this.net_api({ action: 'client/check_duplicates', data: {phone: input.value} }, function(data){ 
                if (data.data == false || self.isNull(data.data)) return;
                else
                {
                  self.$confirm("קיים כבר משתמש" + data.data.name).then(() => {
                    self.$router.push('/client/' + data.data.id)
                    //do something...
                  });
                }
              } );
            }
            break;
          }
          case 'email':
          {
            if (input.value != '' && !this.isNull(this.frm_old_data.email) && this.frm_old_data.email != input.value)
            {
              this.net_api({ action: 'client/check_duplicates', data: { email: input.value } }, function(data){ 
                if (data.data == false || self.isNull(data.data)) return;
                else
                {
                  self.$confirm("קיים כבר משתמש" + data.data.name).then(() => {
                    self.$router.push('/client/' + data.data.id)
                    //do something...
                  });
                }
              } );
            }
            break;
          }
        }

      }

this.frm_old_data = inputs.reduce(function(map, obj) {
    map[obj.name] = obj.value;
    return map;
}, {});


    },
    createCompany()
    {
        var self = this;
        this.net_api({ action: 'companies/add', data: {name: this.frm_data_company} }, function(data){ 
          if (data.data.new_id > 0) self.frm_data_company_id = data.data.new_id;
        } );
    },
    tblActions(rows){
        var self = this;

        if (!rows.is_active)
        {
            return [
                {
                    title: "כרטיס תהליך",
                    onClick: function(rows){
                        self.$router.push('/proccess/' + rows.id);
                    }
                }
            ];
        }
        else
        {
            return [
                {
                    title: "Active",
                    class: 'gray',
                    onClick: function(){
                    }
                }
            ];
        }
    },

    load_proccesses()
    {
      var self = this;
      if (this.id > 0)
      {
        this.net_api({ action: 'proccess/results', data: {client_id: this.id} }, function(data){ 
          
          self.rows = data.data.items;
        } );
      }
    }
  
  },
  data(){
    var self = this;
    return {
      id: this.$route.params.id,
      
      hide_events: false,
      hide_missions: false,

      show_company_details: false,
      frm_data_company: false,
      frm_data_company_id: false,
      frm_old_data: {},

      rows: [ ],
      cols: [
        {title: 'תאריך כניסה', func(rows) { return self.parse_date(rows.created_date); } },
        {title: 'מחלקה', field: 'department_name'},
        {title: 'מוצר', func(rows){ if(rows.product_id > 0) return (self.$store.products[rows.product_id] ? self.$store.products[rows.product_id].name : ''); } },
        //{title: 'מקור', field: 'department_name'},
        {title: 'נציג', field: 'handler_uid', func(rows){ return self.get_user_name(rows.handler_uid) }},
        {title: 'סטאטוס', func(rows){ 
          return self.$store.proccessStatusList.find(function(data){ return rows.status_id == data.id; }).name;  
        } },
        {title: 'מצב הצעה', field: 'status_name'},
        {title: 'תאריך הצעה', field: 'status_name'},
        {title: 'סכום', field: 'status_name'},
        {title: 'תאריך עסקה', field: 'status_name'},
        {title: 'מסמך מצורף', field: 'status_name'},
        //{title: 'שם', func: function(rows){ return rows.department_name + ' - ' + rows.product_name;  } },
      ]
    }
    
  },

  mounted() {

    this.load_proccesses();
      //this.$refs.clientFrm
  },

  watch:
  {
    frm_data_company()
    {
      var self = this;
      this.net_api({ action: 'companies/get_by_name', data: {name: this.frm_data_company} }, function(data){ 
          
          if (!self.isNull(data.data) && data.data.id > 0) self.frm_data_company_id = data.data.id;
          else self.frm_data_company_id = false;
        } );
    }
  }

}
</script>

<style lang="scss">
#footer_save { width: 100%; text-align: center; padding-top:25px;}
.contact_grp { 
  &{ padding: 10px 10px 0 10px !important; border-top: 1px solid #eee;  margin-top: 15px; }
  ul > li { float: right; width: 33%; padding: 0 10px;}
}

.client_page .submit_area { display: none;}
</style>