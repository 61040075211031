<template>
  <div class="home centered">
    
      <div class="box" id="filter_search">
          <h3>חיפוש לקוח</h3>

          <ul>
              <li>
                 <md-field>
                    <label>שם הלקוח</label>
                    <md-input v-model="filter.name"></md-input>
                 </md-field>
              </li>
              <li>
                 <md-field>
                    <label>טלפון</label>
                    <md-input v-model="filter.phone"></md-input>
                 </md-field>
              </li>
              <li>
                 <md-field>
                    <label>אימייל</label>
                    <md-input v-model="filter.email"></md-input>
                 </md-field>
              </li>
              <li>
                 <md-field>
                    <label>שם חברה</label>
                    <md-input v-model="filter.company"></md-input>
                 </md-field>
              </li>
              <li>
                 <md-field>
                    <label>סוג חברה</label>
                    <md-select v-model="filter.company_type">
                        <md-option v-for="handler in $store.companyTypes" :key="handler.id" :value="handler.id">{{handler.name}}</md-option>
                    </md-select>
                 </md-field>
              </li>
              <li>
                <md-autocomplete v-model="filter_product_comp" :md-options="products_ac">
                    <label>מוצר</label>
                </md-autocomplete>

              </li>
              <li v-if="$store.user.has_manager_perms">
                 <md-field>
                    <label>נציג</label>
                    <md-select v-model="filter.proccess_handler">
                        <md-option value="">כולם</md-option>
                        <md-option v-for="handler in $store.users" :key="handler.id" :value="handler.id">{{handler.display_name}}</md-option>
                    </md-select>
                 </md-field>
              </li>
              <li>
                 <md-field>
                    <label>מחלקה</label>
                    <md-select v-model="filter.proccess_department">
                        <md-option v-for="dep in $store.departments" :value="dep.id" :key="dep.id">{{dep.name}}</md-option>
                    </md-select>
                 </md-field>
              </li>
              <li>
                    <VueCtkDateTimePicker v-model="filter.proccess_date" range="true" format="YYYY-MM-DD" label="תאריך כניסה:" />
              </li>
              <li>
                  <VueCtkDateTimePicker v-model="filter.proccess_proposal_date" range="true" format="YYYY-MM-DD" label="תאריך הצעה:" />
              </li>
              <li>
                  <VueCtkDateTimePicker v-model="filter.proccess_proposal_sign_date" range="true" format="YYYY-MM-DD" label="תאריך עסקה:" />
              </li>
              <li>
                <VueCtkDateTimePicker v-model="filter.proccess_meeting_date" range="true" format="YYYY-MM-DD" label="תאריך פגישה:" />
              </li>
          </ul>
          <div class="clr"></div>
          <div>ייצא תוצאות ל: <a class="btn" @click="export_excel()">אקסל</a></div>
      </div>

      <div class="box">
        <ul>
            <li v-for="field in reportFields" :key="field.name">
                <md-checkbox v-model="field.value" value="1">{{field.name}}</md-checkbox>
            </li>
        </ul>

      </div>

        <router-link to="/new_client" class="btn">הוספת לקוח חדש</router-link>
      <div class="box">
        <TableObject :cols="cols" :rows="rows" :options="tblActions" />
      </div>
  </div>
</template>

<script>
import TableObject from '@/components/table.vue';


import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
 

export default {
  components: { TableObject, VueCtkDateTimePicker },

  methods: {
      
      init_list(){
          var self = this;

        self.rows = [];

        this.net_api({ 'action': 'client/results', data: {search: this.filter } }, function(data){
            
            self.$emit('loaded');
            //console.log(data);
            self.rows = data.data.items;
            self.lastupdated = Date.now()

        });
    },

    tblActions(rows){
        var self = this;

        if (!rows.is_active)
        {
            return [
                {
                    title: "כרטיס לקוח",
                    onClick: function(rows){
                        self.$router.push('client/' + rows.id);
                    }
                }
            ];
        }
        else
        {
            return [
                {
                    title: "Active",
                    class: 'gray',
                    onClick: function(){
                    }
                }
            ];
        }
    },

    export_excel(){
        
        this.net_api({ 'action': 'client/export_excel', data: {search: this.filter } }, function(data){
       
            alert('https://admin.crm.nadlancenter.co.il/' + data.data)

        });
    }
  
  },
  data(){
    return {
 reportFields: [
          {field: 'aaa', name: 'מתי', value: false},
          {field: 'aaa', name: 'מתי', value: false},
          {field: 'aaa', name: 'מתי', value: false},
          {field: 'aaa', name: 'מתי', value: false},
          {field: 'aaa', name: 'מתי', value: false},
          {field: 'aaa', name: 'מתי', value: false},
          {field: 'aaa', name: 'מתי', value: false},
          {field: 'aaa', name: 'מתי', value: false},
      ],

        filter: {
            name: '',
            phone: '',
            email: '',
            company: '',
            company_type: '',
            proccess_product: '',
            proccess_department: '',
            proccess_date: '',
            proccess_status: '',
            proccess_proposal_date: '',
            proccess_proposal_sign_date: '',
            proccess_meeting_date: '',
            proccess_handler: this.get_me().id,
        },
      rows: [],
      cols: [
        {title: 'שם', field: 'name'},
        {title: 'מייל', field: 'email'},
        {title: 'טלפון', field: 'phone'},
        {title: 'חברה', func: function(rows) { return rows.company_data.name } },
      ]
    }
    
  },
  computed:{
      products_ac(){
          var newList = [];
          for (let i in this.$store.products) newList.push(this.$store.products[i].name);
          return newList;
      },
      filter_product_comp:
      {
          get()
          {
              let item = this.$store.products[this.filter.proccess_product];
              return (!this.isNull(item) ? item.name : '');
          },
          set(newVal)
          {
              var ret = '';
              for (let i in this.$store.products)
              {
                  if (this.$store.products[i].name == newVal)
                  {
                      ret = this.$store.products[i].id;
                      break;
                  }
              }

            this.filter.proccess_product = ret;
              return ret;
          }
      }
  },

  mounted(){
      this.init_list()
  },
  watch: {
      
    filter: {
        handler(){
            this.init_list()
        // do stuff
        },
        deep: true
    }
  }

}
</script>

<style lang="scss" scoped>
#filter_search { 
    li { width:33%; float: right; padding: 0 15px; margin-bottom: 15px; }
    li .md-field {margin-bottom: 0px; }

    li:nth-child(3n+1) { clear: both;}

}
</style>