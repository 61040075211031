<template>

      <div id="tickets">
          <div class="tickets_table box" v-if="loaded">
              <a class="btn" @click="add_new_ticket({type: type, proccess_id: proccess_id})" v-if="proccess_id > 0">הוספה</a>
<table>
                <thead class="line th">
                    <th class="td checkbox_td"></th>
                    <th class="td date_td"><span>נוצר ב</span></th>
                    <th class="td short_td" v-if="isNull(type) || type==''"><span>סוג</span></th>
                    <th class="td short_td"><span>נציג</span></th>
                    <th class="td mid_td"><span>תהליך</span></th>
                    <th class="td short_td"><span>איש קשר</span></th>
                    <th class="td short_td"><span>חברה</span></th>
                    <th class="td short_td"><span>טלפון</span></th>

                    <template v-if="type=='event'">
                        <th class="td short_td"><span>מתאם</span></th>
                        <th class="td mid_td"><span>מיקום פגישה</span></th>
                    </template>

                    <th class="td short_td" v-if="type!='event'"><span>דחיפות</span></th>
                    <th class="td long_td" v-if="type!='event'"><span>תוכן</span></th>
                    <th class="td date_td"><span>תאריך יעד</span></th>
                    <th class="td hour_td"><span>שעה</span></th>
                    
                    <!--<th class="td short_td"><span>סטטוס</span></th>-->
                </thead>
                <tbody>
                    <tr :class="'line tr ' + (open_ticket.id==ticket.id ? 'active' : '') + ' priority_' + ticket.priority" v-for="ticket in tickets" :key="ticket.id" @click="openTicket(ticket);" >
                        <td class="td checkbox_td"><md-checkbox v-model="ticket.is_completed_parsed" @change="openTicket(ticket); show_close_ticket_menu=true; /*set_complete(ticket, $event);openTicket(ticket);*/"></md-checkbox></td>
                        <td class="td date_td"><span v-text="ticket.created_date_parsed.date"></span></td>
                        <td class="td short_td"  v-if="isNull(type) || type==''"><span v-text="(ticket.type == 'mission' ? 'משימה' : 'פגישה')"></span></td>
                        <td class="td short_td"><span v-text="get_user_name(ticket.handler_uid)"></span></td>
                        <td class="td mid_td"><span v-text="isNull(ticket.proccess_data) ? '' : ticket.proccess_data.department_name"></span></td>
                        <td class="td short_td"><router-link v-if="!isNull(ticket.proccess_data) && !isNull(ticket.proccess_data.client_data)" :to="'/client/'+ticket.proccess_data.client_data.id" class="under_line"><span v-text="(ticket && ticket.proccess_data && ticket.proccess_data.client_data ? ticket.proccess_data.client_data.name : '')"></span></router-link></td>
                        <td class="td short_td"><span  v-text="(ticket && ticket.proccess_data && ticket.proccess_data.client_data && ticket.proccess_data.client_data.company_data ? ticket.proccess_data.client_data.company_data.name : '')"></span></td>
                        <td class="td short_td"><span  v-text="(ticket && ticket.proccess_data && ticket.proccess_data.client_data ? ticket.proccess_data.client_data.phone : '')"></span></td>
                            <template v-if="type=='event'">
                                <td class="td short_td"><span v-text="get_user_name(ticket.event_coordinator_user_id)"></span></td>
                                <td class="td mid_td"><span>מיקום פגישה</span></td>
                            </template>
                        
                        <td class="td short_td" v-if="type!='event'"><span v-text="get_priority_name(ticket.priority)"></span></td>
                        <td class="td long_td" v-if="type!='event'"><span v-text="ticket.subject"></span></td>
                        <td class="td date_td"><span v-text="ticket.due_date_parsed.date"></span></td>
                        <td class="td hour_td"><span v-text="ticket.due_date_parsed.hour"></span></td>
                        <!--<td class="td short_td"><span v-text="ticket.status"></span></td>-->

                    </tr>
                </tbody>
                </table>
          </div>

          <div id="ticket_container" class="box" v-if="open_ticket">
            <a v-if="!open_ticket.is_completed_parsed" @click="/*open_ticket.is_completed_parsed=true;*/ show_close_ticket_menu=true; /*set_complete(open_ticket, true)*/" class="btn">סיום משימה</a>
            <a v-if="open_ticket.is_completed_parsed" @click="open_ticket.is_completed_parsed=false; set_complete(open_ticket, false)" class="btn">פתיחה מחדש</a>
                        
            <div>
                <md-dialog :md-active.sync="show_close_ticket_menu" :md-click-outside-to-close="false" v-if="type!='event'">
                <md-dialog-title>סיום משימה</md-dialog-title>

                <md-dialog-actions >
                        <md-button class="md-primary" @click="set_complete(open_ticket, true, 'new')">משימה/פגישה חדשה</md-button>
                        <md-button class="md-primary" @click="set_complete(open_ticket, true, 'norelevant'); show_close_ticket_menu = false; show_close_ticket_menu_reason=true; ">לא רלוונטי</md-button>
                </md-dialog-actions>
                </md-dialog>
                 <md-dialog :md-active.sync="show_close_ticket_menu" :md-click-outside-to-close="false" v-if="type =='event'">
                <md-dialog-title> האם הפגישה הסתיימה?</md-dialog-title>

                <md-dialog-actions >
                        <md-button class="md-primary" @click="update_event_status(open_ticket, true)">כן</md-button>
                        <md-button class="md-primary" @click="update_event_status(open_ticket, false)">לא </md-button>
                </md-dialog-actions>
                </md-dialog>

                <md-dialog :md-active.sync="show_close_ticket_menu_reason" :md-click-outside-to-close="false">
                <md-dialog-title>סיום משימה</md-dialog-title>

                <md-dialog-actions>

                    <div style="display:block; width:100%;">
                        <label for="movie">בחר סיבה</label>
                        <div v-for="opt in $store.noRelevant" :key="opt.id"><md-radio v-model="reasonId" :value="opt.id">{{opt.name}}</md-radio></div>
                        <div><md-radio v-model="reasonId" value="-1">אחר</md-radio></div>

                        <div v-if="reasonId==-1">
                            <md-field>
                                <label>טקסט חופשי</label>
                                <md-input v-model="reasonText"></md-input>
                            </md-field>
                        </div>

                        <div><a class="btn" @click="norelevant_finish()">סיום</a></div>


                    </div>
                </md-dialog-actions>
                </md-dialog>

            </div>
            
            <div>
                <label>סוג</label>
                <md-radio v-model="open_ticket.type" value="event" @change="save_ticket">פגישה</md-radio>
                <md-radio v-model="open_ticket.type" value="mission" @change="save_ticket">משימה</md-radio>
            </div>
               <div>
                <label>סטאטוס תהליך</label>
                <md-field>
          <label for="movie">סטאטוס תהליך</label>
          <md-select v-model="open_ticket.proccess_data.status_id" name="status_id" id="status_id" @md-selected="save_ticket">
            <md-option v-for="status in $store.proccessStatus" :key="status.id"  :value="status.id" @change="save_ticket">{{status.name}}</md-option>
            
          </md-select>
        </md-field>
            </div>
            <div>
                <label>דחיפות</label>
                
                <md-radio v-for="(priority,index) in priorities" :key="priority" v-model="open_ticket.priority" :value="index" @change="save_ticket">{{priority}}</md-radio>
            </div>

            <VueCtkDateTimePicker v-model="open_ticket.due_date" minute-interval="5"  right="true" format="YYYY-MM-DD HH:mm" @change="save_ticket" />
            <div style="margin-bottom:20px;"></div>
            <template v-if="open_ticket.type == 'event'">
                <div style="border:1px solid #eee; border-radius:5px; padding:25px; margin-bottom:25px;">
                <h3>פרטי הפגישה:</h3>
                
                <md-field>
                    <label>נושא</label>
                    <md-input v-model="open_ticket.event_subject" @change="save_ticket"></md-input>
                </md-field>

                <md-field>
                    <label>מיקום</label>
                    <md-input v-model="open_ticket.event_location" @change="save_ticket"></md-input>
                </md-field>

                <md-field>
                    <label>מתאם פגישה</label>
                    
                    <md-select v-model="open_ticket.event_coordinator_user_id" @md-selected="save_ticket">
                        <md-option v-for="user in handlersList" :key="user.id" :value="user.id">{{user.display_name}}</md-option>
                    </md-select>

                </md-field>

                <md-field>
                    <label>זימון משתתפים</label>
                    <!-- <md-input v-model="open_ticket.event_participants" @change="save_ticket"></md-input> -->
                    <TagsInput :value="open_ticket.event_participants" @tags-changed="update_tags" :key="open_ticket.id" />
                </md-field>

                <!--
                <md-field>
                    <label>פירוט</label>
                    <md-input v-model="open_ticket.event_description" @change="save_ticket"></md-input>
                </md-field>
                -->
                <div><a class="btn" v-if="open_ticket.event_subject != '' && open_ticket.event_location != '' && open_ticket.event_description != ''" @click="send_invitation(open_ticket)">שלח זימון</a></div>
                </div>
            </template>


            <md-field>
                <label>גורם מטפל</label>
                
                <md-select v-model="open_ticket.handler_uid" @md-selected="save_ticket">
                    <md-option v-for="user in handlersList" :key="user.id" :value="user.id">{{user.display_name}}</md-option>
                </md-select>

            </md-field>

            <md-field>
                <label>תוכן</label>
                <md-input v-model="open_ticket.subject" @change="save_ticket"></md-input>
            </md-field>
            <!--
            <md-field>
                <label>מידע נוסף</label>
                <md-textarea v-model="open_ticket.description" md-autogrow  @change="save_ticket"></md-textarea>
            </md-field>
            -->
    <!--
            <Comments :ticket_id="this.open_ticket.id" :key="this.open_ticket.id" />
            -->
          </div>
          
      </div>

</template>

<script>
  
import TagsInput from '@/components/forms/inputs/tags.vue';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
 
/*import Comments from './comments.vue';*/
export default {
    props: ["proccess_id","client_id", "type", "handler", "filter_by", "upcoming", "past_missions"],
    components: {/* Comments,*/ VueCtkDateTimePicker, TagsInput},
  data(){
    return {
        loaded: false,
        ticket_loaded: false,
        open_ticket: false,
        show_close_ticket_menu: false,
        show_close_ticket_menu_reason: false,
        tickets: [],
        reasonId: '',
        reasonText: '',
    }
  },
  methods: {
      openTicket(ticket){
          if (this.show_close_ticket_menu)
          {
              alert('יש לסיים את הטיקט הפתוח');
          } 
          else
          {

              if (this.open_ticket.id == ticket.id)
              {
                     this.open_ticket = false
              }
              else
              {
                  this.open_ticket = ticket;
              }

          }
          
      },
        closeTicket(){
         
              this.open_ticket = false;
          
          
      },
      add_new_ticket(settings){
                
        var today = new Date();
        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate() + ' ' + today.getHours() + ":" + today.getMinutes();

        this.open_ticket = { id: 0, handler_uid: this.get_me().id, subject: '', description: '', priority: '1', type: 'mission', created_date_parsed: { date: 'now' }, due_date: date, due_date_parsed: { date: 'now' }, proccess_id: '', event_coordinator_user_id: this.get_me().id }
        if (!this.isNull(settings) && settings.type != '') this.open_ticket.type = settings.type;
        if (!this.isNull(settings) && settings.proccess_id > 0) 
        {
            this.open_ticket.proccess_id = settings.proccess_id;
            this.open_ticket.proccess_data = {};
        }
      },
      save_ticket(){
        var self = this;

        this.net_api({ 'action': 'tickets/update_basic_data', data: { proccess_id: (this.open_ticket.proccess_id > 0 ? this.open_ticket.proccess_id : this.proccess_id), data: { 
                subject: this.open_ticket.subject, 
                description: this.open_ticket.description, 
                type: this.open_ticket.type, 
                priority: this.open_ticket.priority, 
                proccess_status: this.open_ticket.proccess_data.status_id, 
                due_date: this.open_ticket.due_date, 
                handler_uid: this.open_ticket.handler_uid, 

                event_coordinator_user_id: this.open_ticket.event_coordinator_user_id,
                event_subject: this.open_ticket.event_subject,
                event_location: this.open_ticket.event_location,
                event_participants: this.open_ticket.event_participants,
                event_description: this.open_ticket.event_description,
            }, id: this.open_ticket.id }}, function(data){
                if (self.open_ticket.id == 0)
                {
                    self.open_ticket.id = data.data;
                    self.open_ticket.created_date_parsed = 'now';
                    self.tickets.unshift(self.open_ticket);
              
                // alert(self.open_ticket.id)
                }
                
            });
      },
      send_invitation(ticket)
      {
        var self = this;
        this.net_api({ 'action': 'tickets/send_invitation', data: { id: ticket.id}}, function(){
                        
            self.$toasted.show("הזמנה נשלחה", { 
            theme: "toasted-primary", 
            position: "top-left", 
            duration : 5000
            });

        });
      },
      set_complete(ticket, event, action)
      {
/*
alert(ticket.id)
console.log(event);
*/

//var self = this;


            this.net_api({ 'action': 'tickets/update', data: { field: 'is_completed', value: (event ? '1':'0'), id: ticket.id }}, function(){
           
            });

            switch (action)
            {
                case 'new': { this.add_new_ticket({ type: ticket.type, proccess_id: ticket.proccess_id  }); break;}
                case 'norelevant': {
                    break;
                }
            }

            this.show_close_ticket_menu = false;

      },
      norelevant_finish()
      {
          this.show_close_ticket_menu_reason = false;

            this.net_api({ 'action': 'proccess/change_status', data: { proccess_id: this.open_ticket.proccess_data.id, status_id: 100, reason_id: this.reasonId, reason_text: this.reasonText, proccess_data: this.open_ticket.proccess_data }}, function(){

            });
      },
      update_event_status(ticket, status)
      {
            this.net_api({ 'action': 'tickets/update', data: { field: 'event_is_occred', value: (status ? '1':'0'), id: ticket.id }}, function(){
           
            });
            this.show_close_ticket_menu = false;
      },


      update_tags(val)
      { 
         //alert(this.open_ticket.event_participants)
         this.open_ticket.event_participants = val;
         this.save_ticket();
      }
  },
  mounted(){
    var self = this;
       

    var api_data = { };
    if (this.proccess_id > 0) api_data.proccess_id = this.proccess_id;
    else if (this.client_id > 0) api_data.client_id = this.client_id;

    if (!this.isNull(this.type) && this.type != '') api_data.type = this.type;
    if (!this.isNull(this.handler) && this.type != '') api_data.handler_uid = this.handler;
    if (!this.isNull(this.upcoming) && this.upcoming != '') api_data.upcoming = this.upcoming;
    if (!this.isNull(this.past_missions) && this.upcoming != '') api_data.past_missions = this.past_missions;
    if (!this.isNull(this.filter_by) && this.filter_by != '') api_data.filter_by = this.filter_by;

    this.net_api({ 'action': 'tickets/results', data: api_data}, function(data){
        
        self.$emit('loaded');
        //console.log(data);

        self.tickets = data.data; 
        if (self.tickets.length == 0) { self.$emit('no_results'); }
        self.loaded = true;
    });
  },
  computed:
  {
      tk_due_date()
      {
          return this.open_ticket.due_date;
      },
      handlersList()
      {
          return this.$store.users;
      },
      priorities(){ return this.$store.prioritiesList; },
      showDialog(){
          return true;
      }
  },
  watch:{
      tk_due_date: {
        handler(/*val*/){
           // alert(val)
           this.save_ticket();
        }
      }
  }
}
</script>

<style lang="scss" scoped>
#tickets {
    .priority_2{background-color: rgba(230, 194, 39, 0.15);}
    .priority_3{background-color: rgba(201, 64, 64, 0.15);}
    .under_line{text-decoration: underline;color: #000;}
    &{ display: flex; }
    table { width: 100%; border-collapse: collapse; table-layout: fixed;
    border-spacing: 0;}
    .tickets_table
    {
        & { flex: 2;  }
        .line { width:100%; border-bottom: 1px solid #eee; font-size: 14px;
            .td {
                & { /*float: right;*/ line-height: 46px; padding-left: 15px;  /*width:100px;*/ min-height: 30px; }
                
                &.checkbox_td { width:60px; text-align: center;
                    .md-checkbox { margin: 0px;} 
                }
                /*
                &.date_td {width:80px;  }
                &.hour_td { width:50px; }
                &.type_td { width:70px; }
                &.proccess_td { width:70px; }
                &.title_td { width:250px; }
                &.priority_td { width:50px; }
                
                &.short_td {width:80px;  }
                &.mid_td {width:140px;  }
                &.long_td {width:250px;  }
                */
            }
            &.tr .td {
                & { text-overflow: ellipsis; white-space: nowrap; overflow: hidden;  }
                 &.date_td {  color: #999;  }
                &.hour_td { color: #999; }
                &.type_td { font-weight: bold; }
            }
            &::after { content: ""; display: block; clear: both; }
            &.active { background: #eee;}
        }

        .th { background: #eee; font-weight: bold;}
    }
    #ticket_container { flex: 1; padding: 25px; margin-right: 25px; }
}
</style>