<template>
 <div>
    <md-field>
    <label v-text="input.title"></label>
    
        <md-select v-model="input.value" name="movie" id="movie">
            <md-option v-for="(val, key) in input.options" :key="key" :value="key">{{val}}</md-option>
          </md-select>

    </md-field>
    </div>
</template>
<script>


export default {
    props: ["input"],
  data(){
      return {
      }
  },
  computed:{
  },
  methods:{
  }
}
</script>
