<template>
 <div>
        <table class="table-striped">
            <thead>
                <tr>
                    <th v-for="col in cols" :key="col.title">
                        <div v-text="col.title"></div>
                       <!-- <input type="text" v-model="col.filter" /> -->
                    </th>
                    <th v-if="!isNull(options)">פעולות</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in rowsComp" :key="item.id">
                    <td v-for="col in cols" :key="col.title">
                        <span v-html="get_value(item, col)"></span> 
                    </td>
                    <td v-if="!isNull(options)">
                        <a :class="'btn ' + (!isNull(action.class) ? action.class : '')" v-for="action in options(item)" :key="action.title" v-text="action.title" @click="action.onClick(item)"></a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>

export default {
    props: ["cols", "rows", "options"],
  data(){
      return {
      }
  },
  computed:{
    rowsComp(){
        var ret = [];

        for (let i in this.rows)
        {
            var row = this.rows[i];

            var ret_ins = true;

            for (let z in this.cols)
            {
                var col = this.cols[z];
                if (!this.isNull(col.filter) && col.filter != '' )
                {
                    ret_ins =  (this.get_value(row,col).search(new RegExp(col.filter, "i")) != -1);
                    if (ret_ins === false) break;
                   // alert(row);
                }
            }

            if (ret_ins) ret.push(row);
        }

        return ret;
    }
  },
  methods:{
      get_value(item,col){
          if (!this.isNull(col.func)) return col.func(item);
          return item[col.field]

      }
  }
}
</script>
<style lang="scss" scoped>
table {
    & { width:100%; }
    th {
        & {   padding:0.75rem}
        input { width:calc(100% - 5px); max-width: 100px; border:none; border-bottom:1px solid #ddd; padding-left:8px;  }
    }

    td { border-top: 1px solid #dee2e6;}

    tr{
        &:nth-child(1){border-bottom: none;;border-top: none;margin-bottom: 10px;} 
        &:nth-child(1) th{padding-bottom: 15px;}
        &{border-bottom: 1px solid #e7e7e7;border-top: 1px solid #e7e7e7;} 
        td{padding: 5px;color: #212f56;font-size: 15px; height: 40px;}
        a { color:inherit; text-decoration: underline;}
    }

    .btn { background: none; color: #0077ff !important;}
    .btn.gray { background: none; color: #666 !important; text-decoration: none !important;}
}
</style>