import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
      products: {},
      departments: {},
      companyTypes: {},
      prioritiesList: {},
      proccessStatusList: {},
      users: [],
      user: {},
    },
    mutations: {
      increment (state) {
        state.count++
      }
    }
  })
  