<template>
  <div id="app" :class="'rtl'">
    
    <main id="page-wrap" v-if="loaded" :class="(show_login ? 'no_header' : '')">

      <div id="page_content">
        <LoginPage v-if="show_login" />
        
        <template v-if="!show_login">
            <Header :user_data="user_data" :menu="menu" />
            
            <Bar :user_data="user_data" />

            <router-view :key="$route.path" @show_loader="loaded=false" @loaded="loaded=true" ></router-view>
            
        </template>
      </div>

    </main>
    
    <div v-if="!loaded" id="app_loading">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
<link rel="stylesheet" href="//fonts.googleapis.com/css?family=Roboto:400,500,700,400italic|Material+Icons">

  </div>
</template>

<script>

import Header from './components/header.vue'
import LoginPage from './views/login.vue'
import Bar from './components/bar.vue'

window.api_url = 'https://admin.crm.nadlancenter.co.il/api/'; 

export default {
  name: 'app',
  components: {
    Header,LoginPage, Bar
  },
  data(){
    return {
      
      user_data: {},
      show_login: false,
      loaded: false,
    }
    
  },
  methods: {
    
  },
  mounted() {

    window.appVue = this;
    var self = this;
    
    if (!this.user)
    {
      this.show_login = true;
      this.loaded = true; 
    }
    else
    {
 //     this.net_api({ action: 'new_lead', data: { name: 'עידו פורטל', phone: '05257752229', email: 'i4doyoyoyo@gmailf.com', job_title: 'מלך', campaign: 'asd'} });
      // categories 
      this.net_api({ action: 'general' }, function(data){ 
        
        self.user_data = data.data.user;
        self.playlists = data.data.playlists;
        self.menu = data.data.menu;

        self.$store.users = data.data.users;
        self.$store.user = data.data.user;
        self.$store.departments = data.data.departments;
        self.$store.products = data.data.products;
        
        self.$store.companyTypes = data.data.lists.company_types;
        self.$store.proccessStatus = data.data.lists.proccess_status;
        self.$store.proccessStatusList = data.data.lists.proccess_status;
        self.$store.noRelevant = data.data.lists.no_relevant;
        
        self.$store.prioritiesList ={
          "1": 'רגילה',
          "2": 'בינונית',
          "3": 'גבוהה',
        };

        if (!(self.user_data.id > 0))
        {
          self.show_login = true;
        }

        self.loaded = true;

      } );

  }
}
}
</script>