<template>

      <header class="head">
        <router-link to="/" id="logo"></router-link>
        <div id="head1">
          <div class="centered">

              <ul class="menu">
                <li v-for="item in menu" :key="item.name"><router-link :to="item.link" v-text="item.name"></router-link></li>
              </ul>

          </div>

          <div id="menu_left_area">
            
            <ul class="left_menu">
              <li><div id="user_drop"><span>בוקר טוב, {{ get_me().display_name }} | <a @click="logout()">התנתק</a></span></div></li>
            </ul>

            </div>
          </div>

      </header>
</template>

<script>
  
export default {
  props: ["user_data", "menu"],
  data(){
    return {
      show_user_popup: false
    }
  },
  methods: {
    logout(){
      localStorage.removeItem('user_token');
      document.location = '/';
    }

  }
}
</script>

<style lang="scss" scoped>

  header { 
    background: #fff; min-height: 54px;  box-shadow: 1px 0 20px 0 rgba(0,0,0,0.3); position: fixed; top: 0px; left: 0px; right:200px; z-index: 10;
    @media only screen and (min-width: 600px) { }
    @media only screen and (max-width: 600px) { position: fixed; left: 0px; top: 0px; right:0px; z-index: 99; padding-left: 50px; }

    #head1 {
      &{ color:#222f3c; height: 54px; line-height: 54px;}
      .menu { list-style:none; margin-right: -25px;
        li { display: inline-block;
         a { color: #fff; display: block; padding: 0 25px;}
        }
      }
    }

  }
  #menu_left_area { position: absolute; left: 20px; top: 0px; bottom: 0px;}

  .left_menu {
    li { display: inline-block; padding-right: 25px; position: relative; }
  }


</style>